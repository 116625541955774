@import '../node_modules/antd/dist/antd.less';

body {
    background-color: #018fd0;
}

.table {
    margin-top: 2em;
    border-radius: 15px;
    padding: 15px;
    min-height: 60em;
    // add box shadow
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom: 5em;
}

.frontLogo {
  // center the image
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    border-radius: 15px;
}

h1, h3, .reportReceived {
    color: rgb(234, 234, 234);
    text-align: center;
  }
  
  .fourZeroFour {
    font-family: Arial, sans-serif;
    color: rgb(202, 202, 202);
     text-align: center;
    font-size: medium;
  }

  .intro {
    font-size: medium;
    max-height: 25em;
    overflow: scroll;
    padding: 1em;
    margin-bottom: 5em;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
    border-radius: 15px;
    box-shadow: 5px 10px 8px #888888;
  }

  .quote {
        font-family: serif;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        max-width: 85%;
        font-size: 1.35rem;
        font-weight: bold;
  }

  .reportBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-radius: 15px;
    color: white;
    background-color: #018fd0;
    margin-bottom: 5em;
  }

  .reportPageLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 15px;
  }

